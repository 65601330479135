import React, { Suspense, lazy } from 'react'
import { BlogsWithPaginationV2 } from '@ashleynexvelsolutions/blogs-with-pagination-v2'
import { Seo2 } from '@ashleynexvelsolutions/seo2'
import { graphql } from 'gatsby'
import { Layout } from '../components/Layout'
import {
  SeoFunction,
  PageSpecificSchemaFunction,
  BlogsWithPaginationV2Function,
} from '../DataImports'

const PageSpecificSchema = lazy(() =>
  import('@ashleynexvelsolutions/page-specific-schema').then(module => ({
    default: module.PageSpecificSchema,
  })),
)

const BlogIndex = ({ data }) => {
  return (
    <Layout>
      <div className="py-10 lg:py-28 bg-white">
        <BlogsWithPaginationV2 {...BlogsWithPaginationV2Function(data)} />
      </div>
      <Suspense fallback="loading">
        <PageSpecificSchema {...PageSpecificSchemaFunction(data)} />
      </Suspense>
    </Layout>
  )
}

export const pageQuery = graphql`
  query WordPressPostArchive {
    allWpPost(sort: { fields: [date], order: DESC }) {
      nodes {
        excerpt
        link
        date(formatString: "MMM DD, YYYY")
        title
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        featuredImageAltText {
          featuredImageAltText
        }
      }
    }
    sitePage(path: { eq: "/blog/" }) {
      path
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    wp {
      acfOptionsCommonItems {
        commonItems {
          commonItemsPostHeroImageDefault {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          commonItemsPostHeroImageDefaultAltText
        }
      }
      allSettings {
        readingSettingsPostsPerPage
      }
    }
  }
`
export default BlogIndex
export const Head = ({ data }) => <Seo2 {...SeoFunction(data)} />
